module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-layout@4.13.0_gatsby@5.13.1/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.1_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.7.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
